<template>
  <v-container>
    <v-row
      no-gutters
      class="text-page"
    >
      <v-col cols="12">
        <h1>
          Privacy Policy
        </h1>

        <h2>Privacy Policy of Adhere.ly</h2>
        <p>
          Adhere.ly collects some Personal Data from its Users.
        </p>

        <h3>
          Policy summary
        </h3>

        <p>
          Personal Data collected for the following purposes and using the
          following services:
        </p>
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Access to third-party accounts
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Stripe account access</b></span>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </div>
      </div> -->
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Backup saving and management
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Backup on Google Drive</b></span>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </div>
      </div> -->
        <h3>
          Contacting the User
        </h3>
        <h4>Contact form</h4>
        <p>
          Personal Data: email address; first name; last name; website
        </p>
        <h3>
          Displaying content from external platforms
        </h3>
        <h4>Google Fonts</h4>
        <p>
          Personal Data: Usage Data; various types of Data as specified in the
          privacy policy of the service
        </p>
        <h3>YouTube video widget</h3>
        <p>
          Personal Data: Cookies; Usage Data
        </p>
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Hosting and backend infrastructure
        </div>
        <div class="text-page-block-description">
          <span class="d-block"
            ><b>Amazon Web Services (AWS) and DigitalOcean</b></span
          >
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </div>
      </div> -->
        <h3>
          Hosting and backend infrastructure
        </h3>
        <h4>
          Google Firebase
        </h4>
        <p>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </p>
        <h3>
          Managing contacts and sending messages
        </h3>
        <h4>Mandrill</h4>
        <p>Personal Data: email address; Usage Data</p>
        <h4>Firebase Notifications</h4>
        <p>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </p>
        <!-- <br /><br />
          <span class="d-block"><b>Mailchimp</b></span>
          Personal Data: email address-->
        <h4>Twilio</h4>
        <p>Personal Data: phone number</p>
        <h3>
          Tag Management
        </h3>
        <h4>Google Tag Manager</h4>
        <p>Personal Data: Cookies; Usage Data</p>
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          User database management
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Intercom</b></span>
          Personal Data: Cookies; email address; Usage Data; various types of
          Data as specified in the privacy policy of the service
        </div>
      </div> -->
        <h3>
          Analytics
        </h3>
        <h4>Google Analytics, Analytics collected directly, HubSpot on adhere.ly homepage</h4>
        <p>Personal Data: Cookies; Usage Data</p>
        <h4>
          Personal Data: Cookies; unique device identifiers for advertising
          (Google Advertiser ID or IDFA, for example); Usage Data
        </h4>
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Commercial affiliation
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Amazon Affiliation</b></span>
          Personal Data: Cookies; Usage Data
        </div>
      </div> -->
        <h3>
          Data transfer outside the EU
        </h3>
        <h4>
          Other legal basis for Data transfer abroad, Data transfer abroad
          based on consent and Data transfer from the EU and/or Switzerland
          to the U.S based on Privacy Shield
        </h4>
        <p>
          Personal Data: various types of Data
        </p>
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Handling payments
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Stripe</b></span>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </div>
      </div> -->
        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Infrastructure monitoring
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Uptime Robot</b></span>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </div>
      </div> -->
        <h3>
          Registration and authentication
        </h3>
        <h4>Google OAuth</h4>
        <p>
          Personal Data: various types of Data as specified in the privacy
          policy of the service
        </p>
        <h4>Direct registration</h4>
        <p>Title, Organization, First Name, Last Name, Specialty, NPI number and state.</p>

        <!-- <div class="text-page-block">
        <div class="text-page-block-title">
          Traffic optimization and distribution
        </div>
        <div class="text-page-block-description">
          <span class="d-block"><b>Cloudflare</b></span>
          Personal Data: Cookies; various types of Data as specified in the
          privacy policy of the service
        </div>
      </div> -->
        <h3>
          Types of Data collected
        </h3>
        <p>
          Among the types of Personal Data that Adhere.ly collects, by itself or
          through third parties, there are: Cookies; Usage Data; unique device
          identifiers for advertising (Google Advertiser ID or IDFA, for example);
          email address; first name; last name; phone number; address; password;
          company name; country; state; various types of Data; field of activity;
          profile picture; academic background; website.
        </p>
        <p>
          Complete details on each type of Personal Data collected are provided in
          the dedicated sections of this privacy policy or by specific explanation
          texts displayed prior to the Data collection. Personal Data may be
          freely provided by the User, or, in case of Usage Data, collected
          automatically when using Adhere.ly Unless specified otherwise, all Data
          requested by Adhere.ly is mandatory and failure to provide this Data may
          make it impossible for Adhere.ly to provide its services. In cases where
          Adhere.ly specifically states that some Data is not mandatory, Users are
          free not to communicate this Data without consequences to the
          availability or the functioning of the Service. Users who are uncertain
          about which Personal Data is mandatory are welcome to contact the Owner.
          Any use of Cookies – or of other tracking tools – by Adhere.ly or by the
          owners of third-party services used by Adhere.ly serves the purpose of
          providing the Service required by the User, in addition to any other
          purposes described in the present document and in the Cookie Policy, if
          available.
        </p>
        <p>
          Users are responsible for any third-party Personal Data obtained,
          published or shared through Adhere.ly and confirm that they have the
          third party's consent to provide the Data to the Owner.
        </p>
        <h3>
          Mode and place of processing the Data
        </h3>
        <p>
          The Owner takes appropriate security measures to prevent unauthorized
          access, disclosure, modification, or unauthorized destruction of the
          Data. The Data processing is carried out using computers and/or IT
          enabled tools, following organizational procedures and modes strictly
          related to the purposes indicated. In addition to the Owner, in some
          cases, the Data may be accessible to certain types of persons in charge,
          involved with the operation of Doxy.me (administration, sales,
          marketing, legal, system administration) or external parties (such as
          third-party technical service providers, mail carriers, hosting
          providers, IT companies, communications agencies) appointed, if
          necessary, as Data Processors by the Owner. The updated list of these
          parties may be requested from the Owner at any time.
        </p>
        <h3>
          Legal basis of processing
        </h3>
        <p>
          The Owner may process Personal Data relating to Users if one of the
          following applies:
        </p>
        <ul>
          <li>
            Users have given their consent for one or more specific purposes.
            Note: Under some legislations the Owner may be allowed to process
            Personal Data until the User objects to such processing (“opt-out”),
            without having to rely on consent or any other of the following
            legal bases. This, however, does not apply, whenever the processing
            of Personal Data is subject to European data protection law;
          </li>
          <li>
            provision of Data is necessary for the performance of an agreement
            with the User and/or for any pre-contractual obligations thereof;
          </li>
          <li>
            processing is necessary for compliance with a legal obligation to
            which the Owner is subject;
          </li>
          <li>
            processing is related to a task that is carried out in the public
            interest or in the exercise of official authority vested in the
            Owner;
          </li>
          <li>
            processing is necessary for the purposes of the legitimate interests
            pursued by the Owner or by a third party.
          </li>
        </ul>
        <p>
          In any case, the Owner will gladly help to clarify the specific legal
          basis that applies to the processing, and in particular whether the
          provision of Personal Data is a statutory or contractual requirement, or
          a requirement necessary to enter into a contract.
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  head: {
    title: {
      inner: 'Privacy Policy'
    },
    meta: [
      {
        name: 'description',
        content: 'Privacy Policy',
        id: 'desc'
      }
    ]
  }
}
</script>

<style scoped>

h1 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 28px;
  line-height: 1;
  margin-bottom: 1rem;
}

h2 {
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 26px;
  line-height: 1;
  margin-bottom: 1rem;
}

h3 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 0.5rem;
  margin-top: 0.75rem;
}

h4 {
  font-family: var(--font-family-primary);
  font-weight: 900;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 0.75rem;
}

h5 {
  color: #ACB5BD;
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 14px;
  line-height: 1;
  margin-bottom: 1rem;
}

p {
  color: #0C0C0C;
  font-family: var(--font-family-primary);
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
}

ul {
  margin-bottom: 1rem;
}
</style>